<template>
  <div class="card">
    <p class="title">平台女儿墙</p>
    <van-form
      @submit="onSubmit"
      :submit-on-enter="false"
      validate-trigger="onSubmit"
      scroll-to-error
      error-message-align="right"
    >
      <van-field
        @keydown="handleInput"
        v-model.number="form.Thickness"
        input-align="right"
        label="厚度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写厚度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.EastHeight"
        input-align="right"
        label="东侧高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写东侧高度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.WestHeight"
        input-align="right"
        label="西侧高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写西侧高度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.SourthHeight"
        input-align="right"
        label="南侧高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写南侧高度' }]"
        @focus="$event.target.select()"
      />
      <!-- <van-field
        v-model.number="form.NorthSideHigh"
        input-align="right"
        label="北侧高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写北侧高度' }]"
        @focus="$event.target.select()"
      /> -->
      <div class="item">
        <van-field label="是否有装饰物" input-align="right" readonly />
        <span @click.stop="decorationShow = true"
        >{{ form.TrueOrFalseDecorations }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <ul class="options twoline" v-show="decorationShow">
          <li @click="chooseDecoration('是')">是</li>
          <li @click="chooseDecoration('否')">否</li>
        </ul>
      </div>
      <van-field
        @keydown="handleInput"
        v-if="form.TrueOrFalseDecorations == '是'"
        v-model.number="form.DecorationsHeight"
        input-align="right"
        label="装饰物高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写装饰物高度' }]"
      />
      <div class="item">
        <van-field label="是否可移除" input-align="right" readonly />
        <span class="span" @click.stop="IsRemovableTypeShow = true"
        >{{ form.TrueOrFalseRemove }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <ul class="options widthOptions" v-show="IsRemovableTypeShow">
          <li @click="chooseIsRemovable('女儿墙可移除')">女儿墙可移除</li>
          <li @click="chooseIsRemovable('装饰物可移除')">装饰物可移除</li>
          <li @click="chooseIsRemovable('均可移除')">均可移除</li>
          <li @click="chooseIsRemovable('不可移除')">不可移除</li>
        </ul>
      </div>
      <div class="btnbox">
        <div class="cancel" @click="cancelEvent">取消</div>
        <van-button native-type="submit" class="determine"> 确定 </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'

export default {
  emits: ['popupClose'],
  setup(props, { emit }) {
    const data = reactive({
      form: {
        id: "",
        roofID: "",
        Thickness: '', // 厚度
        EastHeight: '', // 东侧高度
        WestHeight: '', // 西侧高度
        SourthHeight: '', // 南侧高度
        NorthSideHigh: '', // 北侧高度
        TrueOrFalseDecorations: '', // 是否有装饰物
        DecorationsHeight: '', // 装饰物高度
        TrueOrFalseRemove: '' // 是否可移除 1：装饰物可移除  2：均可移除  3：不可移除
      },

      houseOrientationTypeShow: false,
      decorationShow: false,
      IsRemovableTypeShow: false,

    })
    onMounted(() => {
      window.addEventListener('click', methodsMap.handleBodyClick)
    })
    const methodsMap = {
      handleInput(e) {
        // 通过正则过滤小数点后两位
        // e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
        e.target.value = (e.target.value.match(/^(\-)*\d*(\.?\d{0,1})/g)[0]) || null
      },
      handleBodyClick() {
        data.IsRemovableTypeShow = false
      },
      chooseDecoration(item) {
        data.form.TrueOrFalseDecorations = item
        data.decorationShow = false
      },
      chooseIsRemovable(item) {
        data.form.TrueOrFalseRemove = item
        data.IsRemovableTypeShow = false
      },
      onSubmit() {
        emit('popupClose')
      },
      cancelEvent() {
        emit('popupClose')
      }
    }

    return {
      ...toRefs(data),
      ...methodsMap,
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  margin: 24px 0;
  width: 343px;
  min-height: 399px;
  .title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 24px;
  }
  .item {
    position: relative;
    span {
      position: absolute;
      right: 17px;
      top: 8px;
      padding: 0 8px;
      font-size: 14px;
      color: #323233;
      font-weight: 700;
      width: 52px;
      height: 27px;
      border: 1px solid #c7c9cc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .span {
      width: 122px;
    }
    .options {
      z-index: 1;
      width: 52px;
      height: 68px;
      background: #ffffff;
      border: 1px solid #c7c9cc;
      position: absolute;
      right: 17px;
      top: 35px;
      padding-top: 3px;
      padding-left: 8px;
      font-size: 14px;
      color: #c7c9cc;
      li {
        line-height: 20px;
        font-weight: 700;
        color: #1A1A1A;
      }
      li:active {
        color: #04cc66;
      }
    }
    .twoline {
      height: 48px;
    }
    .widthOptions {
      width: 122px;
      height: 88px;
    }
  }
  .btnbox {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 28px;
    .cancel,
    .determine {
      display: inline-block;
      width: 155px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px 4px 4px 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }
    .cancel {
      border: 1px solid #04cc66;
      color: #04cc66;
    }
    .determine {
      background: #04cc66;
    }
  }
}
::v-deep .van-cell-group--inset {
  margin: 0;
}
::v-deep .van-cell:after {
  border: 1px solid rgba(199, 201, 204, 0.6);
}
::v-deep .van-field__label {
  width: 150px;
}
</style>
